















































































import Vue from 'vue';
import CreateHotelClassificationRequestViewModel from '@/src/services/viewModel/resource/CreateHotelClassificationRequestViewModel';
import MembershipResourceViewModel from '@/src/services/viewModel/resource/MembershipResourceViewModel';
import ContactAddressResourceViewModel from '@/src/services/viewModel/resource/ContactAddressResourceViewModel';

export default Vue.extend({
  hasBackButton: true,
  auth: true,
  props: {
    value: { type: Object, default: undefined },
  },
  data: () => ({
    membershipId: undefined as number | undefined,
    isLoading: false,
    myMembership: new MembershipResourceViewModel().fromDTO({
      billingContact: { address: {} },
      businessContact: { address: {} },
    } as Parameters<MembershipResourceViewModel['fromDTO']>[0]) as MembershipResourceViewModel | undefined,
  }),
  computed: {
    languageItems(): {caption: string, id: string}[] {
      return [
        {
          caption: this.$t('page.createMembership.select.german'),
          id: 'de',
        },
        {
          caption: this.$t('page.createMembership.select.french'),
          id: 'fr',
        },
        {
          caption: this.$t('page.createMembership.select.italian'),
          id: 'it',
        },
      ];
    },
    currentLanguage(): string {
      return this.$translation.get().toLowerCase();
    },
    innerValue: {
      get(): CreateHotelClassificationRequestViewModel {
        return this.value ?? {};
      },
      set(value: CreateHotelClassificationRequestViewModel) {
        this.$emit('input', value);
      },
    },
    contactId() {
      return Vue.$router.currentRoute.params.id;
    },
  },
  watch: {
    'innerValue.billingContact.isDifferentFromOwner': {
      deep: true,
      handler() {
        if (this.innerValue.billingContact.isDifferentFromOwner) {
          this.innerValue.billingContact.address = new ContactAddressResourceViewModel();
        } else {
          this.innerValue.billingContact.address = this.myMembership?.businessContact.address!;
        }
      },
    },
  },
  async mounted() {
    this.isLoading = true;
    this.membershipId = this.$service.api.memberships.getMyMembershipId();
    this.myMembership = await this.$service.api.memberships.getMyMembership();
    this.innerValue.businessContact.address = this.myMembership?.businessContact.address!;
    this.innerValue.businessContact.contactId = this.myMembership?.businessContact.contactId;
    this.isLoading = false;
  },
  methods: {},
});
